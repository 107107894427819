import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as blazeSlider from './scripts/slider';
blazeSlider.init();

import * as dynamicTabbing from './scripts/dynamic-tabbing';
dynamicTabbing.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});